<template>
  <div class="layout">
    <div class="flex-one">
      <img
        v-if="!res.list[0].extraParams || !res.list[0].extraParams.isVideo"
        alt=""
        :src="res.list[0].img"
      />
      <video
        v-else
        controls
        :src="res.list[0].extraParams.videoSrc"
        style="width: 100%; height: 100%"
      ></video>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['res'],
  }
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
  .flex-one {
    display: block;
    width: 100%;
    height: 110px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
    }
  }
</style>
